
import draggable from "vuedraggable";
import _ from "lodash";
import setUrl from "~/mixins/setUrl.js";
export default {
  layout: "add",
  name: "PageAdd",
  components: {
    draggable,
  },
  mixins: [setUrl],
  data: () => {
    const checkDetailInput = (rule, value, callback) => {
      if (!value) {
        return callback(
          new Error("Silakan isi detail pertanyaan minimal 20 karakter")
        );
      }
      setTimeout(() => {
        if (value.length < 20) {
          callback(
            new Error("Silakan isi detail pertanyaan minimal 20 karakter")
          );
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      loading: {
        submit: false,
        location: false,
      },
      listLocation: [],
      form: {
        img_logo: "",
        img_background: "",
        name: "",
        description: "",
        location: "",
        account: "1",
        purpose: "",
        locationId: "",
        community_link: "",
        member_name: "Member",
      },
      file: {
        img_logo: null,
        img_background: null,
      },
      community_link_owned: "",
      rules: {
        name: [
          {
            required: true,
            message: "Silakan input nama komunitas",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Silakan input deskripsi",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "Silakan pilih jenis account komunitas",
            trigger: "blur",
          },
        ],
        community_link: [
          {
            required: true,
            message: "Silakan input link komunitas",
            trigger: "blur",
          },
        ],
        location: [
          {
            required: true,
            message: "Silakan pilih jenis location komunitas",
            trigger: "blur",
          },
        ],
        purpose: [
          {
            required: true,
            message: "Silakan pilih tujuan komunitas",
            trigger: "blur",
          },
        ],
        body_content: [
          {
            required: true,
            validator: checkDetailInput,
            message: "Silakan isi detail pertanyaan minimal 60 karakter",
            trigger: "blur",
          },
        ],
      },
      queryId: null,
      purposeOptions: [],
      link_available: null,
      enabledDragLink: true,
      listDragLink: [],
      listInput: {
        title: null,
        value: null,
      },
      draggingLink: false,
      showSelectIcon: false,
      linkSelectIcon: null,
      iconsSosmed: [
        {
          icon: "bxl-instagram",
          info: "Masukan ID Instagram",
          placeholder: "id instagram",
          format: "https://www.instagram.com/",
        },
        {
          icon: "bxl-twitter",
          info: "Masukan ID Twitter",
          placeholder: "id twitter",
          format: "https://twitter.com/",
        },
        {
          icon: "bxl-youtube",
          info: "Masukan Link Youtube",
          placeholder: "masukan link youtube",
          format: "",
        },
        {
          icon: "bxl-facebook",
          info: "Masukan Username Facebook",
          placeholder: "username facebook",
          format: "https://www.facebook.com/",
        },
        {
          icon: "bx-calendar-event",
          info: "Masukan link event kamu",
          placeholder: "link event kamu",
          format: "",
        },
        {
          icon: "bxl-whatsapp",
          info: "Masukan nomor whatsapp",
          placeholder: "nomor whatsapp",
          format: "https://wa.me/",
        },
        {
          icon: "bx-envelope",
          info: "Masukan alamat email",
          placeholder: "alamat email",
          format: " ",
        },
        {
          icon: "bx-map",
          info: "Masukan link map",
          placeholder: "masukan link map",
          format: " ",
        },
        {
          icon: "bx-cart",
          info: "Masukan link toko online kamu",
          placeholder: "masukan link toko",
          format: "",
        },
        {
          icon: "bx-link-alt",
          info: "Masukan link kamu",
          placeholder: "masukan link kamu",
          format: "",
        },
      ],
    };
  },
  watch: {
    "form.community_link"() {
      this.onInputLink();
    },
  },
  mounted() {
    this.checkQueryParams();
    this.fetchCategoryCommunity();
  },
  methods: {
    setLink(val) {
      this.form.community_link = val.replace(/ /g, "-").toLocaleLowerCase();
    },
    selectIcon(item, icon) {
      this.listDragLink[this.linkSelectIcon].icon_id = icon;
      // this.listDragLink[this.linkSelectIcon].value = item.info
      this.showSelectIcon = false;
    },
    isLetterSpace(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9-a-z/\-.]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },
    isLetterPage(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9A-Z-a-z/\s-._]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },
    isLetter(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9A-Za-z/\s-.:@_]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },
    addNewLink() {
      this.listDragLink.push({
        title: "",
        value: "",
        is_active: true,
        icon_id: null,
      });
    },
    removeList(index) {
      this.listDragLink.splice(index, 1);
    },
    showIconLists(index) {
      this.linkSelectIcon = index;
      this.showSelectIcon = true;
    },
    async checkLinkCommunity() {
      if (!this.form.community_link) {
        this.link_available = null;
        return;
      }
      if (this.form.community_link === this.community_link_owned) {
        this.link_available = null;
        return;
      }
      await this.$store
        .dispatch("public/checkCategoryLink", this.form.community_link)
        .then((res) => {
          this.link_available = res.slug_availability || false;
        });
    },
    checkQueryParams() {
      if (this.$route.query && this.$route.query.id) {
        this.queryId = this.$route.query.id;
        this.fetchCommunityById(this.queryId);
      }
    },
    async fetchCategoryCommunity() {
      await this.$store.dispatch("public/getCategoryCommunity").then((res) => {
        this.purposeOptions = res;
      });
    },
    async fetchCommunityById(id) {
      const data = {
        param_id: id,
        param_slug: "",
      };
      await this.$store
        .dispatch("public/getCommunityDetail", data)
        .then((res) => {
          if (res) {
            this.form = {
              img_logo: res.img_logo,
              img_background: res.img_background,
              name: res.community_name,
              description: res.description,
              location: res.city_name,
              locationId: [res.id_city, res.id_province],
              account: `${res.is_public}`,
              purpose: res.communities_category_id,
              community_link: res.slug,
              member_name: res.memberlist_name,
              listDragLink: res.link,
            };
            this.community_link_owned = res.slug;
            this.listDragLink = [];
            res.link.map((obj, index) => {
              this.listDragLink.push(obj);
              this.listDragLink[index].is_active = true;
              if (
                obj.icon_id !== 2 &&
                obj.icon_id !== 4 &&
                obj.icon_id !== 6 &&
                obj.icon_id !== 7 &&
                obj.icon_id !== 8 &&
                obj.icon_id !== 9
              ) {
                this.splitURL(obj, index);
              }
            });
          }
        })
        .catch(() => {});
    },
    splitURL(item, index) {
      const split = item.value.split(this.iconsSosmed[item.icon_id].format);
      this.listDragLink[index].value = split[1];
    },
    async onChangeLoc(val) {
      this.loading.location = true;
      await this.$store
        .dispatch("public/fetchLocationCommunity", val)
        .then((res) => {
          this.listLocation = res.body || [];
          this.loading.location = false;
          console.log(this.listLocation);
        })
        .catch(() => {
          this.loading.location = false;
        });
    },
    submitForm(formName) {
      this.loading.submit = true;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("community_name", this.form.name);
          formData.append("slug", this.form.community_link);
          formData.append("description", this.form.description);
          formData.append("is_public", this.form.account);
          formData.append("communities_category_id", this.form.purpose);
          formData.append("memberlist_name", this.form.member_name);
          formData.append("id_country", 1);
          if (
            this.queryId &&
            this.form.locationId &&
            typeof this.form.location === "string"
          ) {
            formData.append(
              "id_city",
              this.form.locationId ? this.form.locationId[0] : ""
            );
            formData.append(
              "id_province",
              this.form.locationId ? this.form.locationId[1] : ""
            );
          } else if (
            this.queryId &&
            this.form.locationId &&
            typeof this.form.location !== "string"
          ) {
            formData.append(
              "id_city",
              this.form.location ? this.form.location[0] : ""
            );
            formData.append(
              "id_province",
              this.form.location ? this.form.location[1] : ""
            );
          } else {
            formData.append(
              "id_city",
              this.form.location ? this.form.location[0] : ""
            );
            formData.append(
              "id_province",
              this.form.location ? this.form.location[1] : ""
            );
          }
          if (this.form.img_logo && this.file.img_logo) {
            formData.append("img_logo", this.file.img_logo);
          }
          if (this.form.img_background && this.file.img_background) {
            formData.append("img_background", this.file.img_background);
          }

          if (this.listDragLink.length > 0) {
            this.listDragLink.map((o, index) => {
              if (o.is_active) {
                formData.append(`link[${index}][title]`, o.title);
                formData.append(`link[${index}][value]`, o.value);
                formData.append(
                  `link[${index}][is_active]`,
                  o.is_active ? 1 : 0
                );
                formData.append(`link[${index}][icon_id]`, o.icon_id);
              }
            });
          }

          const url = this.queryId
            ? "postSubmitEditCommunity"
            : "postSubmitNewCommunity";
          const payload = {
            formData,
            id: this.queryId,
          };

          await this.$store
            .dispatch(`public/${url}`, payload)
            .then((res) => {
              this.resetForm();
              this.$notify.success({
                message: this.queryId
                  ? "Berhasil edit komunitas"
                  : "Berhasil menambah komunitas",
                offset: 100,
                duration: 3500,
              });
              if (res.body) {
                setTimeout(() => {
                  this.$router
                    .push(this.localeLocation(`/community/${res.body.id}`))
                    .catch((err) => err);
                }, 1000);
              }
              this.loading.submit = false;
              this.active = false;
            })
            .catch((err) => {
              console.log(err);
              this.loading.submit = false;
              this.$notify.error({
                title: "Gagal",
                offset: 100,
                message:
                  "Terjadi kesalahan server, silakan coba beberapa saat lagi",
              });
            });
        } else {
          this.loading.submit = false;
          this.$notify.info({
            message: "Silahkan isi form yang masih kosong",
            offset: 100,
            duration: 3500,
          });
        }
      });
    },
    handleLogo(val) {
      this.file.img_logo = val.file;
      this.form.img_logo = val.thumb;
    },
    handleBackground(val) {
      this.file.img_background = val.file;
      this.form.img_background = val.thumb;
    },
    resetForm() {
      this.form = {
        img_logo: "",
        img_background: "",
        name: "",
        description: "",
        location: "",
        account: 1,
        purpose: "",
        community_link: "",
        member_name: "",
        listDragLink: [],
      };
    },
    onInputLink: _.debounce(function () {
      this.checkLinkCommunity();
    }, 300),
  },
};
