import { render, staticRenderFns } from "./add.vue?vue&type=template&id=76c4e26d&"
import script from "./add.vue?vue&type=script&lang=js&"
export * from "./add.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UploadImage: require('/mnt/volume_berlin_01/frontends/mobile-community/components/uploadImage.vue').default})
